export const DayOne = [
  {
    name: 'Rejestracja uczestników',
    isBreak: true,
    startTime: '2024-11-29T15:45',
    panelLength: '40',
    icon: 'register'
  },

  {
    name: 'Otwarcie wydarzenia',
    isBreak: true,
    startTime: '2024-11-29T16:25',
    panelLength: '5',
    icon: 'opening'
  },

  {
    name: 'Nauka - pasja, która zmienia świat',
    isBreak: false,
    startTime: '2024-11-29T16:30',
    panelLength: '15',
    speakers: 'dr Maciej Kawecki',
    activityType: 'Prelekcja'
  },

  {
    name: 'Sztuka pisania wniosków grantowych',
    isBreak: false,
    startTime: '2024-11-29T16:45',
    panelLength: '60',
    speakers: 'Michał Pietras',
    activityType: 'Warsztat'
  },

  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2024-11-29T17:45',
    panelLength: '15',
    icon: 'coffee'
  },

  {
    name: 'Praktyczne narzędzia dla wnioskodawców',
    isBreak: false,
    startTime: '2024-11-29T18:00',
    panelLength: '60',
    speakers: 'Sylwia Piskorska',
    activityType: 'Warsztat'
  },

  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2024-11-29T19:00',
    panelLength: '20',
    icon: 'coffee'
  },

  {
    name: 'Ochrona własności intelektualnej w nauce: Jak publikować, nie narażając swoich innowacji',
    isBreak: false,
    startTime: '2024-11-29T19:20',
    panelLength: '60',
    speakers: 'Piotr Zakrzewski',
    activityType: 'Prelekcja'
  },

  {
    name: 'Networking',
    isBreak: true,
    startTime: '2024-11-29T20:20',
    panelLength: '60',
    icon: 'networking'
  }
];

export const DayTwo = [
  {
    name: 'Rejestracja uczestników',
    isBreak: true,
    startTime: '2024-11-30T08:30',
    panelLength: '30',
    icon: 'register'
  },

  {
    name: 'Pitch Perfect - Sztuka prezentowania pomysłów po mistrzowsku',
    isBreak: false,
    startTime: '2024-11-30T09:00',
    panelLength: '220',
    speakers: 'Elena Pawęta i Adam Liwiński',
    activityType: 'Warsztat'
  },

  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2024-11-30T12:40',
    panelLength: '20',
    icon: 'coffee'
  },

  {
    name: 'Od pomysłu do finansowania: Jakie wsparcie możesz otrzymać?',
    isBreak: false,
    startTime: '2024-11-30T13:00',
    panelLength: '60',
    speakers:
      'Magdalena Olczak-Nowicka, Jacek Sztolcman, Piotr Grzegorczyk, moderuje: Mariusz Szmigiel',
    activityType: 'Panel'
  },

  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2024-11-30T14:00',
    panelLength: '15',
    icon: 'coffee'
  },

  {
    name: 'Turning research into AI product',
    isBreak: false,
    startTime: '2024-11-30T14:15',
    panelLength: '15',
    speakers: 'Aish Agarwal',
    activityType: 'Prelekcja'
  },

  {
    name: 'Przerwa obiadowa',
    isBreak: true,
    startTime: '2024-11-30T14:30',
    panelLength: '60',
    icon: 'launch'
  },
  {
    name: 'Czy to się sprzeda? Analiza rynku, tworzenie modelu biznesowego oraz produktu',
    isBreak: false,
    startTime: '2024-11-30T15:30',
    panelLength: '110',
    speakers: 'Bart Jaworski',
    activityType: 'Warsztat'
  },

  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2024-11-30T17:20',
    panelLength: '30',
    icon: 'coffee'
  },

  {
    name: 'Mosty innowacji: Różne drogi rozwoju jako naukowiec',
    isBreak: false,
    startTime: '2024-11-30T17:50',
    panelLength: '90',
    speakers:
      'Judyta Sobczyk, Joanna Lipner, Filip Budny, Karolina Tkaczuk, moderuje: Krystyna Rappe-Niemirska',
    activityType: 'Panel'
  },

  {
    name: 'Zakończenie wydarzenia',
    isBreak: true,
    startTime: '2024-11-30T19:20',
    panelLength: '10',
    icon: 'opening'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2024-11-30T19:30',
    panelLength: '90',
    icon: 'networking'
  }
];

export const AgendaFSTS = [DayOne, DayTwo];
