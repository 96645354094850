import {
  AgendaBar,
  MobileAgendaBar
} from '../../templates/workshops/components/agenda';
import {
  AgendaNav,
  AgendaSubHeader,
  AgendaTitleWrapper
} from '../mpk/styled.components';
import {
  AgendaSection,
  FSTSCard,
  FSTSCardCol,
  FSTSCardContainer,
  FSTSCardImg,
  FSTSCardRow,
  FSTSCardTitle,
  FSTSHollowCard,
  FSTSHollowTitle,
  FSTSPreTitle,
  GainzSection,
  HeroLogo,
  HeroSubText,
  HollowText,
  ParnterSection,
  PatronLogo,
  SpeakerSection,
  WhereSection
} from './styled.components';
import {
  Col,
  GradientWrapper,
  InnerColumn50,
  InnerColumn60,
  Row
} from '../../components/sections/styled.components';
import {
  Header2,
  Header3,
  PageHeader,
  SectionHeader,
  Text
} from '../../components/typography';
import {
  PageSection,
  PageWrapper,
  SectionWrapper
} from '../../components/global/flexbox';
import { Prelegent, PrelegentEN } from './data/prelegent';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ASTRA from '../../images/logos/as.png';
import Academy from '../../images/logos/inhub/academy.png';
import { AgendaFSTS } from './data/agendaFSTS';
import { AgendaFSTSEN } from './data/agendaFSTSEN';
import CTTW from '../../images/logos/CTTW UW.png';
import Deepflare from '../../images/logos/deepflare.png';
import Forum from '../../images/logos/forum.png';
import { FstsGalleryImages } from '../../mocks/fsts-gallery';
import { GalleryComponent } from '../../components/galeria/index.old';
import { GalleryImageData } from '../../mocks/gallery';
import Innotech from '../../images/logos/innotech.png';
import Layout from '../../components/layout/layout';
import MUOT from '../../images/logos/muot.jpeg';
import NCBR from '../../images/logos/ncbr.png';
import PACTT from '../../images/logos/pactt.png';
import People from './src/people.png';
import Pikralida from '../../images/logos/pikralida.png';
import RNA from '../../images/logos/rna.png';
import Reactor from '../../images/logos/CommercializationReactor.png';
import Seo from '../../components/seo';
import { StyledHref } from '../../components/global/buttons/styled.components';
import Sygnis from '../../images/logos/sygnis.png';
import Testimonials from '../../components/testimonials';
import { ThemeProvider } from 'styled-components';
import ThisIsIT from '../../images/logos/ThisIsIT.png';
import UPRP from '../../images/logos/uprp.png';
import inUW from '../../images/logos/inUW.png';
import FNP from '../../images/logos/fnp.png';
import MAGLY from '../../images/logos/magly.png';
import IDEAS from '../../images/logos/ideas.png';
import SKNB from '../../images/logos/sknb.png';
import WHEN from './src/calendar.svg';
import WHERE from './src/location.svg';
import WHO from './src/crowd.svg';
import adianano from '../../images/logos/adianano.png';
import creotech from '../../images/logos/creotech.png';
import fstsBg from './src/bg-plain.png';
import inQUBE from '../../images/logos/inQUBE.png';
import { isWindow } from '../../components/global/utils';
import { theme } from '../../themes/default';

function FromScienceToStartup({ data }) {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [currentPage, setCurrentPage] = useState(1);
  const MPKCurrentAgenda = currentLang === 'pl' ? AgendaFSTS : AgendaFSTSEN;
  const CurrentPrelegent = currentLang === 'pl' ? Prelegent : PrelegentEN;
  const ITEMS_PER_PAGE = 1;
  const totalPages = Math.ceil(MPKCurrentAgenda?.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentAgendaItems = MPKCurrentAgenda?.slice(startIndex, endIndex);

  function setDay(index) {
    setCurrentPage(index + 1);
  }

  function nextDay() {
    if (currentPage !== totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  function prevDay() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  const isMobile = isWindow() && window.innerWidth < 768;
  return (
    <ThemeProvider theme={theme}>
      <Layout withHeader={false}>
        <Seo title={t('fsts-title')} description={t('fsts-description')} />
        <PageWrapper>
          <PageSection
            bg_img_size="cover"
            bg_img_size_mobile="cover"
            className="hero"
            bg_img={fstsBg}
            bubbles_bg={''}>
            <GradientWrapper>
              <InnerColumn50 className="hero">
                <HeroLogo
                  style={{ marginTop: '40px' }}
                  src={Academy}
                  alt="Innovations Logo"
                />
                <PageHeader>{t('fsts-hero')}</PageHeader>
                <HeroSubText>{t('fsts-sub-hero')}</HeroSubText>
                <Row style={{ paddingTop: '12px' }}>
                  <StyledHref href="#gain" className="white">
                    {t('fsts-earn')}
                  </StyledHref>
                </Row>
              </InnerColumn50>
              <InnerColumn50 className="hero-img">
                <img width="500" src={People} alt="Inhub Naukowcy" />
              </InnerColumn50>
            </GradientWrapper>
          </PageSection>
          <PageSection className="fsts">
            <SectionWrapper>
              <Row>
                <InnerColumn50>
                  <Trans i18nKey="fsts-elite" parent={Header2} />
                  <Trans i18nKey="fsts-elite-content" parent={Text} />
                  <Trans i18nKey="fsts-elite-content-two" parent={Text} />
                  <Trans i18nKey="fsts-elite-content-three" parent={Text} />
                </InnerColumn50>
                <InnerColumn50>
                  <iframe
                    style={{ width: '100%' }}
                    className="mpk-video"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/wJB3iXRTuEg"
                    title="Aftermovie InHub"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
                </InnerColumn50>
              </Row>
            </SectionWrapper>
          </PageSection>
          <GainzSection
            id="gain"
            className="fsts"
            style={{ marginTop: '3rem', paddingBottom: '3rem' }}
            bg_col="#F5FAFD">
            <SectionWrapper>
              <Col>
                <InnerColumn60 className="small-gap">
                  <FSTSPreTitle>{t('fsts-why-come')}</FSTSPreTitle>
                  <Header2>{t('fsts-gain')}</Header2>
                </InnerColumn60>
                <FSTSCardRow>
                  <FSTSHollowCard>
                    <FSTSHollowTitle>
                      {t('fsts-gain-card1-title')}
                    </FSTSHollowTitle>
                    <HollowText>{t('fsts-gain-card1-text')}</HollowText>
                  </FSTSHollowCard>
                  <FSTSHollowCard>
                    <FSTSHollowTitle>
                      {t('fsts-gain-card2-title')}
                    </FSTSHollowTitle>
                    <HollowText>{t('fsts-gain-card2-text')}</HollowText>
                  </FSTSHollowCard>
                  <FSTSHollowCard>
                    <FSTSHollowTitle>
                      {t('fsts-gain-card3-title')}
                    </FSTSHollowTitle>
                    <Trans>{t('fsts-gain-card3-text')}</Trans>
                  </FSTSHollowCard>
                  <FSTSHollowCard>
                    <FSTSHollowTitle>
                      {t('fsts-gain-card4-title')}
                    </FSTSHollowTitle>
                    <HollowText>{t('fsts-gain-card4-text')}</HollowText>
                  </FSTSHollowCard>
                  <FSTSHollowCard>
                    <FSTSHollowTitle>
                      {t('fsts-gain-card5-title')}
                    </FSTSHollowTitle>
                    <HollowText>{t('fsts-gain-card5-text')}</HollowText>
                  </FSTSHollowCard>
                  <FSTSHollowCard>
                    <FSTSHollowTitle>
                      {t('fsts-gain-card6-title')}
                    </FSTSHollowTitle>
                    <HollowText>{t('fsts-gain-card6-text')}</HollowText>
                  </FSTSHollowCard>
                </FSTSCardRow>
              </Col>
            </SectionWrapper>
          </GainzSection>
          <WhereSection
            className="fsts"
            style={{ marginTop: '3rem', paddingBottom: '3rem' }}
            id="investing">
            <SectionWrapper>
              <Col className="align-none">
                <InnerColumn60>
                  <FSTSPreTitle>{t('fsts-where-when-who')}</FSTSPreTitle>
                  <Header2>{t('fsts-forum')}</Header2>
                </InnerColumn60>
                <FSTSCardContainer>
                  <FSTSCardCol>
                    <FSTSCard>
                      <FSTSCardImg>
                        <img src={WHERE} alt="Ikona gdzie"/>
                      </FSTSCardImg>
                      <Col className="small-gap">
                        <FSTSCardTitle>{t('fsts-where')}</FSTSCardTitle>
                        <Text>{t('fsts-where-txt')}</Text>
                        <img
                          src={Forum}
                          alt="Forum Logo"
                          style={{ marginTop: '12px' }}
                        />
                      </Col>
                    </FSTSCard>
                    <FSTSCard>
                      <FSTSCardImg>
                        <img src={WHEN} alt="Ikona kied"/>
                      </FSTSCardImg>
                      <Col className="small-gap">
                        <FSTSCardTitle>{t('fsts-when')}</FSTSCardTitle>
                        <Text>{t('fsts-when-txt')}</Text>
                      </Col>
                    </FSTSCard>
                  </FSTSCardCol>
                  <FSTSCard>
                    <FSTSCardImg>
                      <img src={WHO} alt="Ikona dla kogo"/>
                    </FSTSCardImg>
                    <Col className="small-gap">
                      <FSTSCardTitle>{t('fsts-who')}</FSTSCardTitle>
                      <Trans
                        i18nKey={'fsts-who-txt'}
                        parent={Text}
                        components={{
                          link1: (
                            <MailTo
                              to="mailto: hello@innovationshub.pl"
                              title="hello@innovationshub.pl"
                            />
                          )
                        }}
                      />
                    </Col>
                  </FSTSCard>
                </FSTSCardContainer>
              </Col>
            </SectionWrapper>
          </WhereSection>
          <AgendaSection>
            <SectionWrapper>
              <div className="flex-child">
                <SectionHeader>
                  <strong>{t('fsts-agenda')}</strong>
                </SectionHeader>
              </div>
              <div className="flex-child" style={{ paddingTop: '60px' }}>
                <AgendaTitleWrapper justify="space-around">
                  {!isMobile ? (
                    AgendaFSTS.map((element, index) => {
                      return (
                        <AgendaSubHeader
                          className={
                            currentPage === index + 1 ? 'active small' : 'small'
                          }
                          onClick={() => setDay(index)}>
                          {t('mpk-day') + (index + 1)}
                        </AgendaSubHeader>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <AgendaNav onClick={() => prevDay()}>←</AgendaNav>
                      <AgendaSubHeader>
                        {t('mpk-day') + currentPage}
                      </AgendaSubHeader>
                      <AgendaNav onClick={() => nextDay()}>→</AgendaNav>
                    </div>
                  )}
                </AgendaTitleWrapper>
                {currentAgendaItems?.map((element, index) => (
                  <div key={index}>
                    {element.map((item) => {
                      if (isMobile) {
                        return (
                          <MobileAgendaBar key={item.id} {...item} isEvent />
                        );
                      }
                      return <AgendaBar key={item.id} {...item} isEvent />;
                    })}
                  </div>
                ))}
              </div>
            </SectionWrapper>
          </AgendaSection>
          <SpeakerSection className="fsts">
            <SectionWrapper>
              <Col>
                <Col className="width-12">
                  <Trans i18nKey="fsts-speakers" parent={Header2} />
                  <Text>{t('fsts-speakers-text')}</Text>
                </Col>
                <Testimonials content={CurrentPrelegent} />
              </Col>
            </SectionWrapper>
          </SpeakerSection>
          <PageSection bg_img_pos="center right">
            <SectionWrapper>
              <SectionHeader>
                <strong>{t('fsts-pictures-title')}</strong>
              </SectionHeader>
              <Text className="center">{t('fsts-pictures-info')}</Text>
              <GalleryComponent content={FstsGalleryImages} />
            </SectionWrapper>
          </PageSection>
          <ParnterSection className="fsts" bg_col="">
            <SectionWrapper>
              <Col>
                <Header2>{t('fsts-partners')}</Header2>
                <Col>
                  <Header3>{t('fsts-strategic')}</Header3>
                  <Row>
                    <PatronLogo
                      className="astra"
                      src={currentLang === 'pl' ? ASTRA : ASTRA}
                    />
                    <PatronLogo src={Forum} alt="Forum Logo" />
                  </Row>
                </Col>
                <Row className="space-between">
                  <Col>
                    <Header3>{t('fsts-partners-eco')}</Header3>
                    <Row>
                      <PatronLogo className="uprp" src={UPRP} alt="fsts Logo" />
                      <PatronLogo src={Innotech} alt="Innotech Logo" />
                    </Row>
                  </Col>
                </Row>
                <Col>
                  <Header3>{t('fsts-financing')}</Header3>
                  <Row>
                    <PatronLogo src={IDEAS} alt="NCBR Ideas Logo" />
                    <PatronLogo src={FNP} alt="FNP logo" />
                    <PatronLogo src={NCBR} alt="NCBR Logo" />
                    <PatronLogo src={ThisIsIT} alt="This is IT Logo" />
                    <PatronLogo src={MUOT} alt="Muot Logo" />
                  </Row>
                  <Row>
                    <PatronLogo src={Deepflare} alt="Deepflare Logo" />
                    <PatronLogo src={Sygnis} alt="Sygnis Logo" />
                    <PatronLogo src={MAGLY} alt="MAGLY logo" />
                    <PatronLogo src={RNA} alt="Explo RNA Logo" />
                    <PatronLogo src={Pikralida} alt="Pikralida Logo" />
                  </Row>
                  <Row>
                    <PatronLogo src={creotech} alt="creotech Logo" />
                    <PatronLogo src={inUW} alt="Inkubator UW logo" />
                    <PatronLogo src={inQUBE} alt="inQUBE Logo" />
                    <PatronLogo src={CTTW} alt="CTTW UW Logo" />
                    <PatronLogo
                      src={PACTT}
                      alt="Porozumienie Akademickich Centrów Transferu Technologii Logo"
                    />
                  </Row>
                  <Row>
                    <PatronLogo src={adianano} alt="Adianano Logo" />
                    <PatronLogo
                      src={Reactor}
                      alt="Commercialization Reactor Logo"
                    />
                  </Row>
                </Col>
                <Col>
                  <Header3>{t('fsts-coorganiser')}</Header3>
                  <Row>
                    <PatronLogo src={SKNB} alt="SKNB Logo" />
                  </Row>
                </Col>
              </Col>
            </SectionWrapper>
          </ParnterSection>
        </PageWrapper>
      </Layout>
    </ThemeProvider>
  );
}

export default FromScienceToStartup;

const MailTo = (props) => {
  return (
    <a
      style={{ color: 'var(--ui-light-blue,#4EBFEE)', textDecoration: 'none' }}
      href={props.to || '#'}
      target="_blank"
      title={props.title || ''}>
      {props.children}
    </a>
  );
};
