export const DayOne = [
  {
    name: 'Participant Registration',
    isBreak: true,
    startTime: '2024-11-29T15:45',
    panelLength: '40',
    icon: 'register'
  },

  {
    name: 'Event Opening',
    isBreak: true,
    startTime: '2024-11-29T16:25',
    panelLength: '5',
    icon: 'opening'
  },

  {
    name: 'Science - Passion that Changes the World',
    isBreak: false,
    startTime: '2024-11-29T16:30',
    panelLength: '15',
    speakers: 'Maciej Kawecki',
    activityType: 'Power speech'
  },

  {
    name: 'The Art of Writing Grant Applications',
    isBreak: false,
    startTime: '2024-11-29T16:45',
    panelLength: '60',
    speakers: 'Michał Pietras',
    activityType: 'Lecture'
  },

  {
    name: 'Break',
    isBreak: true,
    startTime: '2024-11-29T17:45',
    panelLength: '15',
    icon: 'coffee'
  },

  {
    name: 'Practical Tools for Applicants',
    isBreak: false,
    startTime: '2024-11-29T18:00',
    panelLength: '60',
    speakers: 'Sylwia Piskorska',
    activityType: 'Workshop'
  },

  {
    name: 'Break',
    isBreak: true,
    startTime: '2024-11-29T19:00',
    panelLength: '20',
    icon: 'coffee'
  },

  {
    name: 'Intellectual Property Protection in Science: How to Publish Without Compromising Innovations',
    isBreak: false,
    startTime: '2024-11-29T19:20',
    panelLength: '60',
    speakers: 'Piotr Zakrzewski',
    activityType: 'Lecture'
  },

  {
    name: 'Networking',
    isBreak: true,
    startTime: '2024-11-29T20:20',
    panelLength: '60',
    icon: 'networking'
  }
];

export const DayTwo = [
  {
    name: 'Participant Registration',
    isBreak: true,
    startTime: '2024-11-30T08:30',
    panelLength: '30',
    icon: 'register'
  },

  {
    name: 'Pitch Perfect - The Art of Masterfully Presenting Ideas',
    isBreak: false,
    startTime: '2024-11-30T09:00',
    panelLength: '220',
    speakers: 'Elena Pawęta & Adam Liwiński',
    activityType: 'Workshop'
  },

  {
    name: 'Break',
    isBreak: true,
    startTime: '2024-11-30T12:40',
    panelLength: '20',
    icon: 'coffee'
  },

  {
    name: 'From Idea to Funding: What Support Can You Receive?',
    isBreak: false,
    startTime: '2024-11-30T13:00',
    panelLength: '60',
    speakers:
      'Magdalena Olczak-Nowicka, Jacek Sztolcman, Piotr Grzegorczyk, moderator: Mariusz Szmigiel',
    activityType: 'Panel'
  },

  {
    name: 'Break',
    isBreak: true,
    startTime: '2024-11-30T14:00',
    panelLength: '15',
    icon: 'coffee'
  },

  {
    name: 'Turning research into AI product',
    isBreak: false,
    startTime: '2024-11-30T14:15',
    panelLength: '15',
    speakers: 'Aish Agarwal',
    activityType: 'Power speech'
  },

  {
    name: ' Lunch Break',
    isBreak: true,
    startTime: '2024-11-30T14:30',
    panelLength: '60',
    icon: 'launch'
  },
  {
    name: 'Will It Sell? Market Analysis, Creating a Business Model and Product',
    isBreak: false,
    startTime: '2024-11-30T15:30',
    panelLength: '110',
    speakers: 'Bart Jaworski',
    activityType: 'Workshop'
  },

  {
    name: 'Break',
    isBreak: true,
    startTime: '2024-11-30T17:20',
    panelLength: '30',
    icon: 'coffee'
  },

  {
    name: 'Bridges of Innovation: Different Development Paths as a Scientist',
    isBreak: false,
    startTime: '2024-11-30T17:50',
    panelLength: '90',
    speakers:
      'Judyta Sobczyk, Joanna Lipner, Filip Budny, Karolina Tkaczuk, moderator: Krystyna Rappe-Niemirska',
    activityType: 'Panel'
  },

  {
    name: 'Event Closing',
    isBreak: true,
    startTime: '2024-11-30T19:20',
    panelLength: '10',
    icon: 'opening'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2024-11-30T19:30',
    panelLength: '90',
    icon: 'networking'
  }
];

export const AgendaFSTSEN = [DayOne, DayTwo];
