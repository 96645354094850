import frankowski from '../../../images/mpk/prelegent/frankowski.jpg';
import kosiec from '../../../images/people/kosiec.jpg';
import koteja from '../../../images/people/anna-koteja.jpg';
import osica from '../../../images/people/osica.jpg';
import gizinski from '../../../images/people/gizinski.jpg';
import klaszczynski from '../../../images/people/klaszczynski.jpg';
import kowalczuk from '../../../images/people/kowalczuk.jpg';
import garniewski from '../../../images/people/garniewski.jpg';
import jemielity from '../../../images/people/jemielity.jpg';
import tyszkiewicz from '../../../images/people/magdalena-tyszkiewicz.jpg';
import platek from '../../../images/people/platek.jpg';
import pawlowicz from '../../../images/people/bohdan.jpg';
import zakrzewski from '../../../images/people/zakrzewski.jpg';
import bialkowski from '../../../images/people/przemysław-białkowski.png';
import zimny from '../../../images/people/zimny-zajac.jpg';
import sobczyk from '../../../images/people/sobczyk.jpg';
import gulchak from '../../../images/people/gulchak.jpg';
import pietras from '../../../images/people/pietras.jpg';
import szmigiel from '../../../images/people/szmigiel.jpg';
import slapjums from '../../../images/people/slapjums.jpg';
import dwilinski from '../../../images/people/dwilinski.jpg';
import boguszewska from '../../../images/people/boguszewska.jpg';
import kawecki from '../../../images/people/kawecki.jpg';
import piskorska from '../../../images/people/piskorska.jpg';
import paweta from '../../../images/people/paweta.jpeg';
import liwinski from '../../../images/people/liwinski.jpg';
import sztolcman from '../../../images/people/sztolcman.jpg';
import grzegorczyk from '../../../images/people/grzegorczyk.jpg';
import olczak_nowicka from '../../../images/people/olczak-nowicka.jpg';
import jaworski from '../../../images/people/jaworski.jpeg';
import tkaczuk from '../../../images/people/tkaczuk.jpg';
import lipner from '../../../images/people/lipner.jpg';
import budny from '../../../images/people/budny.jpg';
import rappe_niemirska from '../../../images/people/rappe-niemirska.jpg';
import aish from '../../../images/people/aish.jpg';

export const Prelegent = [
  {
    title: 'Prorektor ds. Innowacji i Współpracy, WSB Merito',
    name: 'Maciej Kawecki',
    image: kawecki
  },
  {
    title: 'Dyrektor ds. Działalności Programowej, Fundacja na rzecz Nauki Polskiej',
    name: 'Michał Pietras',
    image: pietras
  },
  {
    title: 'Koordynatorka Grantów R&D, IDEAS NCBR',
    name: 'Sylwia Piskorska',
    image: piskorska
  },
  {
    title: 'Autor, wykładowca UKSW',
    name: 'Piotr Zakrzewski',
    image: zakrzewski
  },
  {
    title: 'Founder IDEAS+LEADERS, Wykładowca SGH',
    name: 'Elena Pawęta',
    image: paweta
  },
  {
    title: 'Dyrektor Operacyjny, InnoTech4Life',
    name: 'Judyta Sobczyk',
    image: sobczyk
  },
  {
    title: 'Dyrektor, Mazowiecka Jednostka Wdrażania Programów Unijnych',
    name: 'Mariusz Frankowski',
    image: frankowski
  },
  {
    title:
      'Dyrektorka ds. Innowacji i Współpracy z Szkolnictwem Wyższym, Astra Zeneca',
    name: 'Dr Karolina Tkaczuk',
    image: tkaczuk
  },
  {
    title:
      'Manager, Specjalista od Przemówień Publicznych',
    name: 'Adam Liwiński',
    image: liwinski
  },
  {
    title: 'Partner, Digital Ocean Ventures',
    name: 'Magdalena Olczak-Nowicka',
    image: olczak_nowicka
  },
  {
    title: 'CEO, Deepflare',
    name: 'Piotr Grzegorczyk',
    image: grzegorczyk
  },
  {
    title: 'Starszy Product Manager, The Stepstone Group',
    name: 'Bart Jaworski',
    image: jaworski
  },
  {
    title: 'CEO, MAGLY',
    name: 'Filip Budny',
    image: budny
  },
  {
    title: 'CEO, Connectly AI',
    name: 'Aish Agarwal',
    image: aish
  },
  {
    title: 'Co-founder, Pikralida',
    name: 'Joanna Lipner',
    image: lipner
  },
  {
    title:
      'Dyrektor Centrum Współpracy i Dialogu, Founder Inkubatora Uniwersytetu Warszawskiego',
    name: 'Jacek Sztolcman',
    image: sztolcman
  },
  {
    title: 'Senior Executive, Członek Rady Doradczej Fundacji Innovations Hub',
    name: 'Mariusz Szmigiel',
    image: szmigiel
  },
  {
    title: 'Dyrektorka ds. Komunikacji i Marketingu, IDEAS NCBR',
    name: 'Krystyna Rappe-Niemirska',
    image: rappe_niemirska
  },
  {
    title: 'Członkini Zarządu, Commercialization Reactor Fund',
    name: 'Ilona Gulchak',
    image: gulchak
  },
  {
    title: 'Koordynator, Narodowe Centrum Nauki',
    name: 'Dr Anna Koteja',
    image: koteja
  },
  {
    title: 'Prezes Zarządu i Założycielka, pro science',
    name: 'Natalia Osica',
    image: osica
  },
  {
    title: 'Prezes, ExploRNA Therapeutics',
    name: 'Prof. Jacek Jemielity',
    image: jemielity
  },
  {
    title:
      'Kierownik ds. Komunikacji Publicznej i Kontaktów z Administracją, Astra Zeneca',
    name: 'Dr Anna Kowalczuk',
    image: kowalczuk
  },
  {
    title: 'Prezes Zarządu, Orlen VC',
    name: 'Marek Garniewski',
    image: garniewski
  },
  {
    title: 'Kierownik Działu Technologicznego i Współzałożyciel, Deepflare',
    name: 'Stanisław Giziński',
    image: gizinski
  },
  {
    title: 'Wiceprezes, Fundacja PACTT',
    name: 'Paweł Płatek',
    image: platek
  },
  {
    title: 'Doradca Zastępcy Dyrektora, NASK',
    name: 'Bohdan Pawłowicz',
    image: pawlowicz
  },
  {
    title: 'Prezes Zarządu i Założyciel, Adianano',
    name: 'Tom Slapjums',
    image: slapjums
  },
  {
    title: 'Wiceprezes Zarządu, Creotech Instruments SA',
    name: 'Jacek Kosiec',
    image: kosiec
  },
  {
    title: 'Kierownik Działu Zarządzania Projektami, Pikralida',
    name: 'Magdalena Tyszkiewicz',
    image: tyszkiewicz
  },
  {
    title:
      'Doradca w obszarze przedsiębiorczości, inQube Uniwersytecki Inkubator Przedsiębiorczości UE',
    name: 'Maciej Klaczyński',
    image: klaszczynski
  },
  {
    title: 'Dyrektor Departamentu Komercjalizacji, Centrum Łukasiewicz',
    name: 'Magda Bohusz-Boguszewska',
    image: boguszewska
  },
  {
    title: 'Prezes Fundacji, PACTT',
    name: 'Robert Dwiliński',
    image: dwilinski
  },
  {
    title: 'Redaktor Naczelna, Medonet',
    name: 'Anna Zimny-Zając',
    image: zimny
  },
  {
    title: 'Dyrektor Działu Innowacje w Grupa Interia.pl',
    name: 'Przemysław Białkowski',
    image: bialkowski
  }
];

export const PrelegentEN = [
  {
    title: 'Vice-Rector for Innovation and Collaboration, WSB Merito',
    name: 'Maciej Kawecki',
    image: kawecki
  },
  {
    title: 'Director of Program Activities, Foundation for Polish Science',
    name: 'Michał Pietras',
    image: pietras
  },
  {
    title: 'R&D Grants Coordinator, IDEAS NCBR',
    name: 'Sylwia Piskorska',
    image: piskorska
  },
  {
    title: 'Author, Lecturer at UKSW',
    name: 'Piotr Zakrzewski',
    image: zakrzewski
  },
  {
    title: 'Founder of IDEAS+LEADERS, Lecturer at SGH',
    name: 'Elena Pawęta',
    image: paweta
  },
  {
    title: 'COO, InnoTech4Life',
    name: 'Judyta Sobczyk',
    image: sobczyk
  },
  {
    title: 'Director, Mazovian Unit for Implementation of EU Programs',
    name: 'Mariusz Frankowski',
    image: frankowski
  },
  {
    title:
      'Senior Director of Innovation and Academic Alliance, AstraZeneca',
    name: 'Dr Karolina Tkaczuk',
    image: tkaczuk
  },
  {
    title:
      'Manager, Public Speaking Specialist',
    name: 'Adam Liwiński',
    image: liwinski
  },
  {
    title: 'Partner, Digital Ocean Ventures',
    name: 'Magdalena Olczak-Nowicka',
    image: olczak_nowicka
  },
  {
    title: 'CEO, Deepflare',
    name: 'Piotr Grzegorczyk',
    image: grzegorczyk
  },
  {
    title: 'Senior Product Manager, The Stepstone Group',
    name: 'Bart Jaworski',
    image: jaworski
  },
  {
    title: 'CEO, MAGLY',
    name: 'Filip Budny',
    image: budny
  },
  {
    title: 'CEO, Connectly AI',
    name: 'Aish Agarwal',
    image: aish
  },
  {
    title: 'Co-founder, Pikralida',
    name: 'Joanna Lipner',
    image: lipner
  },
  {
    title:
      'Director of Cooperation and Dialogue Center, Founder of University of Warsaw Incubator',
    name: 'Jacek Sztolcman',
    image: sztolcman
  },
  {
    title: 'Senior Executive, Advisory Board Member at Innovations Hub Foundation',
    name: 'Mariusz Szmigiel',
    image: szmigiel
  },
  {
    title: 'Director of Communication and Marketing, IDEAS NCBR',
    name: 'Krystyna Rappe-Niemirska',
    image: rappe_niemirska
  },
  {
    title: 'Board Member, Commercialization Reactor Fund',
    name: 'Ilona Gulchak',
    image: gulchak
  },
  {
    title: 'Coordinator, National Science Centre',
    name: 'Anna Koteja PhD',
    image: koteja
  },
  {
    title: 'CEO and Founder, pro science',
    name: 'Natalia Osica',
    image: osica
  },
  {
    title: 'President, ExploRNA Therapeutics',
    name: 'Prof. Jacek Jemielity',
    image: jemielity
  },
  {
    title: 'Head of Public Communication and Government Relations, AstraZeneca',
    name: 'Anna Kowalczuk PhD',
    image: kowalczuk
  },
  {
    title: 'Vice President of the Executive Board, Creotech Instruments SA',
    name: 'Jacek Kosiec',
    image: kosiec
  },
  {
    title: 'Head of Project Management Department, Pikralida',
    name: 'Magdalena Tyszkiewicz',
    image: tyszkiewicz
  },
  {
    title: 'Entrepreneurship Advisor, inQube University Business Incubator EU',
    name: 'Maciej Klaczyński',
    image: klaszczynski
  },
  {
    title: 'CEO, Orlen VC',
    name: 'Marek Garniewski',
    image: garniewski
  },
  {
    title: 'Head of Technology Department and Co-founder, Deepflare',
    name: 'Stanisław Giziński',
    image: gizinski
  },
  {
    title: 'Vice President, PACTT Foundation',
    name: 'Paweł Płatek',
    image: platek
  },
  {
    title: "Deputy Director's Advisor, NASK",
    name: 'Bohdan Pawłowicz',
    image: pawlowicz
  },
  {
    title: 'CEO and Co-founder, Adianano',
    name: 'Tom Slapjums',
    image: slapjums
  },
  {
    title: 'Director of the Commercialization Department, Łukasiewicz Center',
    name: 'Magda Bohusz-Boguszewska',
    image: boguszewska
  },
  {
    title: 'President, PACTT foundation',
    name: 'Robert Dwiliński',
    image: dwilinski
  },
  {
    title: 'Editor-in-Chief, Medonet',
    name: 'Anna Zimny-Zając',
    image: zimny
  },
  {
    title: 'Director of the Innovation Department at Interia.pl Group',
    name: 'Przemysław Białkowski',
    image: bialkowski
  }
];
